import _ from "lodash"

export default {
  data() {
    return {}
  },
  methods: {
    handleValidationErrors(response, scope) {
      let self = this
      try {
        let errorsFields =
          _.get(response, "data.error.fields") ||
          _.get(response, "error.fields") ||
          _.get(response, "fields")
        if (errorsFields) {
          _.forEach(errorsFields, function (val, key) {
            var valuesArr = _.isArray(val.description)
              ? val.description
              : [val.description]
            _.forEach(valuesArr, function (val2) {
              self.$validator.errors.add({
                scope: scope,
                field: key,
                msg: val2,
              })
            })
          })
        } else if (
          response?.data?.status === "error" ||
          response?.data?.status === "fail"
        ) {
          console.log("response.data--general_error_field", response.data)
          self.$validator.errors.add({
            scope: scope,
            field: "general_error_field",
            msg: response?.data?.message || response?.data?.error?.description,
          })
        }
      } catch (e) {
        console.log("Error on handle API errors", e)
      }
    },
    changeSort(header) {
      let column = header.value
      if (header.sortable === undefined || header.sortable === true) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }
      }
    },

    getFullname(user, returnEmpty = false) {
      if (user) return user.first_name + " " + user.last_name
      if (returnEmpty) return ""
      return "Unknown user"
    },

    openFullscreen(elem) {
      if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen()
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen()
      } else if (elem.requestFullscreen) {
        elem.requestFullscreen()
      }
    },

    /* eslint-disable-next-line */
    closeFullscreen(elem) {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!Object.prototype.hasOwnProperty.call(obj2, key)) {
          result.push(key)
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key)
          result.splice(resultKeyIndex, 1)
        }
        return result
      }, Object.keys(obj2))
      return diff
    },
    // Get base 64 url from file
    getBase64Url(file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => {
          resolve(reader.result)
        }
        reader.readAsDataURL(file)
      })
    },
  },
  computed: {
    /* eslint-disable-next-line */
    getCompleteUrl(item) {
      return (item) => {
        if (item.hostname == "test-jwn-signaling.staging.forasoft.com") {
          return item.protocol + "://" + item.hostname
        }
        return item.protocol + "://" + item.hostname + ":" + item.port
      }
    },
  },
}
