import { CommonConnectionService } from "@/common/websocket.service"
import { mapGetters } from "vuex"
import ReconnectingWebSocket from "reconnecting-websocket"

export default {
  created() {
    try {
      let self = this
      if (
        !self.websocket_common_conn ||
        !(self.websocket_common_conn instanceof ReconnectingWebSocket)
      ) {
        self.$store.dispatch(
          "saveWsConnAction",
          CommonConnectionService.init(self)
        )
      }
    } catch (err) {
      console.warn("websoket-connection mixin", err)
    }
  },

  computed: {
    ...mapGetters({
      websocket_common_conn: "getWebsocketCommonConnection",
      isAuthenticatedWs: "isAuthenticatedWs",
    }),
  },
}
