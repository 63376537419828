<template>
  <div>
    <v-form
      data-vv-scope="form-event"
      key="eventCreateUpdate"
      data-testid="form-event"
    >
      <v-card>
        <v-card-title data-testid="title">
          <span class="headline" v-html="getTitle()"></span>
          <v-spacer></v-spacer>
          <span v-if="activeEvent.is_started" class="green--text pr-3"
            >Started</span
          >
          <span v-if="!activeEvent.is_started" class="red--text pr-3"
            >Not Started</span
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              sm="12"
              md="12"
              align="right"
              class="pt-0"
              v-if="
                $can('event.start', 'ws_event') ||
                $can('event.stop', 'ws_event')
              "
            >
              <v-btn
                v-if="!activeEvent.is_started"
                @click="startStopCurrEvent(true)"
                :color="Pallette.actionButtons.play"
                :loading="loadingStartStopEvent"
                data-testid="btn-start-event"
              >
                Start current event
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
              <v-btn
                v-if="activeEvent.is_started"
                @click="startStopCurrEvent(false)"
                :color="Pallette.actionButtons.delete"
                :loading="loadingStartStopEvent"
                data-testid="btn-end-event"
              >
                End current event
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-col>
          </v-row>

          <v-tabs fixed-tabs dark v-model="activeTab" class="mb-4">
            <v-tabs-slider color="orange"></v-tabs-slider>
            <v-tab
              :key="1"
              :to="`${baseRoute}`"
              exact
              data-testid="btn-tab-main-settings"
            >
              <v-icon left>mdi-settings-box</v-icon>
              Main settings
            </v-tab>
            <v-tab
              :key="2"
              :to="`${baseRoute}/connections`"
              :disabled="editedIndex === -1"
              exact
              data-testid="btn-tab-connections"
            >
              <v-icon left>mdi-lan-connect</v-icon>
              Connections
            </v-tab>
          </v-tabs>

          <router-view></router-view>

          <!--          <v-tabs-items touchless class="mt-3">-->
          <!--            <v-tab-item-->
          <!--              :transition="false"-->
          <!--              :reverse-transition="false"-->
          <!--              :key="1"-->
          <!--              :value="`${baseRoute}`"-->
          <!--            >-->
          <!--              <router-view></router-view>-->
          <!--            </v-tab-item>-->
          <!--            <v-tab-item-->
          <!--              :transition="false"-->
          <!--              :reverse-transition="false"-->
          <!--              :key="2"-->
          <!--              :value="`${baseRoute}/connections`"-->
          <!--            >-->
          <!--              <router-view></router-view>-->
          <!--            </v-tab-item>-->
          <!--          </v-tabs-items>-->
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import WsConnMixin from "@/mixins/websocket-connection.js"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  mixins: [GeneralMixin, WsConnMixin],

  data: () => ({
    activeTab: null,
    modal: false,
  }),

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetEventItem").then(() => {
      self.getItemData().then(() => {
        self.$store
          .dispatch("setCompanyForEvent", {
            editedIndex: self.editedIndex,
            groupKey: self.currentUser.group_key,
          })
          .then(() => {
            self.$store.dispatch("saveLoaderAction", false)
          })
      })
    })
  },

  mounted() {
    this.activeTab = this.$router.history.current.path
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Create new event"
        : `<span class="blue-grey--text text--lighten-4">Edit event</span> ${this.activeEvent.name}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getEventItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    startStopCurrEvent(action = false) {
      let self = this
      self.$store.dispatch("loadingStartStopEvent", true).then(() => {
        self.$root.$emit("eStartStopCurrentEvent", action)
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeEvent: "activeEvent",
      ordersRegisteredList: "ordersRegisteredList",
      isSuperUser: "isSuperUser",
      currentUser: "currentUser",
      Pallette: "get_Pallette",
      loadingStartStopEvent: "loadingStartStopEvent",
    }),
    isActiveConnectionsTab() {
      return this.isSuperUser && !this.activeEvent.group_key
    },
    baseRoute() {
      return this.editedIndex === -1
        ? "/store/event/create"
        : `/store/event/edit/${this.editedIndex}`
    },
  },
}
</script>
